import React from 'react';

export default function ProductDetailsSectionInfo({ productData, isSpecification }) {

	return (
		<div className='product-section-info'>
			{
				productData.shortDescription != ("" && null && undefined) &&
				<>
					<p className='product-section-info-text'>Product Description</p>
					<p style={{ fontFamily: 'Poppins-Light', marginTop: 10 }}>{productData?.shortDescription ? productData.shortDescription : null}</p>
				</>
			}
			{
				productData.longDescription != ("" && null && undefined) &&
				<>
					<p className='product-section-info-text' style={{ marginTop: 10, }}>Details and Specifications</p>
					<p style={{ fontFamily: 'Poppins-Light', marginTop: 10 }}>{productData?.longDescription ? productData.longDescription : null}</p>
				</>
			}

		</div>
	)
}



