import { useEffect, useState, useContext } from 'react';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import { AuthContext } from '../pages/RootPage';

import { useLocation, useNavigate } from 'react-router-dom';
import RightArrow from '../assets/svg/right.svg'

import '../styles/HeaderProductList.css'
function HeaderProductlist({ parent, heading }) {
    const navigate = useNavigate()
    const { toggleDrawer } = useContext(AuthContext);
    const location = useLocation()



    return (

        <div className='containerHeaderProduct'>

            <div className='drawer-icon-product' >
                <img src={DrawerIcon} style={{ width: 20, height: 20 }} onClick={() => toggleDrawer()} />
            </div>
            <div className='routeWrapper'>
                <p className='route-names' onClick={() => navigate('/')}>Home</p>
                <img src={RightArrow} />
                <p className='route-names' onClick={() => navigate('/Categories')}>Categories</p>
                <img src={RightArrow} />
                {
                    location?.state?.heading &&
                    <p style={{ fontFamily: 'Poppins-Regular', fontSize: '20px', flexWrap: 'nowrap', margin: 0 }}>{location?.state?.heading}</p>
                }
                
            </div>
        </div>

    )

}



export default HeaderProductlist