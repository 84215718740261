import { useLocation } from "react-router-dom";
import * as language from '../constants/languages';
import HeaderProductList from "../components/HeaderProductList";
import SpinnerLoader from "../components/SpinnerLoader";
import { getSearchProducts, getOfferProducts } from '../services/services';
import { useEffect, useState } from "react";
import ProductListingComponent from "../components/ProductListingComponent";
import ArrowLeft from '../assets/svg/keyboardright.svg'
import ArrowRight from '../assets/svg/keyboardleft.svg'

export default function ProductListPage() {
    let loadBrandProducts
    let loadCateProducts
    const location = useLocation()
    const [productData, setproductData] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [isOffer, setisOffer] = useState(0)
    const [filterApplied, setfilterApplied] = useState(false)
    const [appliedFilters, setappliedFilters] = useState([])
    const [page, setpage] = useState(1)
    const [hasNext, sethasNext] = useState(true)
    const [hasPrevious, sethasPrevious] = useState(false)
    const [count, setcount] = useState(0)
    const [currentPage, setcurrentPage] = useState()
    const [filterModalVisible, setfilterModalVisible] = useState(false)
    const [viewSelect, setviewSelect] = useState(1)
    useEffect(() => {
        if (location.state.params.isOffer == 1) {

            setisLoading(true)
            setisOffer(1)
            const offerId = (location.state.params.offerId)
            getOfferDetails(true,offerId)
            return () => {
                localStorage.removeItem('cateFilter');
                localStorage.removeItem('brandFilter');
                localStorage.removeItem('ratingFilter');
                localStorage.removeItem('priceApplied');
                localStorage.removeItem('lowRange');
                localStorage.removeItem('highRange');
            }
        }
        else {
            loadProductsData(location.state.params);
        }
    }, [])


    //get offerdetails
    async function getOfferDetails(isScroll,offerId) {
        const branchId = localStorage.getItem('storeId');
        const token = localStorage.getItem('access_token');
        const cart = localStorage.getItem('cartItems');
        await getOfferProducts(language.companyidValue, offerId, page, 30, branchId, token).then((offerProducts) => {
            console.log(offerProducts,"offerProducts")
            if (offerProducts.status === "success") {
                if (cart) {
                    const cartItems = JSON.parse(cart);
                    for (let i of offerProducts.data) {
                        const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == i.productId);
                        if (elementsIndex != -1) {
                            i.cartQty = cartItems.customerCartItems[elementsIndex].qty
                        }
                    }

                    setisLoading(false)
                    setproductData(offerProducts.data)
                    sethasNext(offerProducts.hasNext)
                    sethasPrevious(offerProducts.hasPrevious)
                    setcount(offerProducts.totalCount)

                } else {

                    setisLoading(false)
                    setproductData(offerProducts.data)
                    sethasNext(offerProducts.hasNext)
                    sethasPrevious(offerProducts.hasPrevious)
                    setcount(offerProducts.totalCount)

                }

            } else {
                setisLoading(true)
                sethasNext(false)
                sethasPrevious(false)
                setcount(0)

            }

        })
    }



    async function loadProductsData(params) {
        const branchId = localStorage.getItem('storeId');
        if (params != "undefined") {
            if (params.identity === "caterogries") {
                getProducts('', params.id, "", "", '', '', page, true);
                var cate_filter_array = [{ id: params.id }];
                loadCateProducts = params.id;
                await localStorage.setItem('cateFilter', JSON.stringify(cate_filter_array));
            }
        }
    }

    // get search products
    async function getProducts(productName, category, brand, rating, lowRange, highRange, page, isScroll) {
        // if (typeof loadCateProducts !== "undefined") {
        //     category = [...category, loadCateProducts]
        // }
        // if (typeof loadBrandProducts !== "undefined") {
        //     brand = [...brand, loadBrandProducts]
        // }
        const token = localStorage.getItem('access_token');
        const branchId = localStorage.getItem('storeId');
        const cart = localStorage.getItem('cartItems');
        await getSearchProducts(language.companyidValue, productName, category, brand, rating, lowRange, highRange, branchId, token, page,).then((searchProduct) => {
            if (searchProduct.status === "success") {
                // if (this.cart) {
                // 	this.cartItems = JSON.parse(this.cart);

                // 	for (let i of searchProduct.data) {
                // 		const elementsIndex = this.cartItems.customerCartItems.findIndex(element => element.product.productId == i.productId);
                // 		if (elementsIndex != -1) {
                // 			i.cartQty = this.cartItems.customerCartItems[elementsIndex].qty
                // 		}
                // 	}
                // 	this.setState({
                // 		isLoading: false,
                // 		refresh: false,
                // 		productData: searchProduct.data,
                // 		spinnerOverlay: false,
                // 		hasNext: searchProduct.hasNext,
                // 		hasPrevious: searchProduct.hasPrevious,
                // 		count: searchProduct.totalCount,
                // 		currentPage: searchProduct.currentPage
                // 	})
                // } else {
                setisLoading(false)
                setproductData(searchProduct.data)
                sethasNext(searchProduct.hasNext)
                sethasPrevious(searchProduct.hasPrevious)
                setcount(searchProduct.totalCount)
                setcurrentPage(searchProduct.currentPage)

                // }

            }
            else {
                setisLoading(true)
                sethasNext(false)
                sethasPrevious(false)
                setcount(0)
                setcurrentPage(undefined)

            }

        })
            .catch((error) => {
                setisLoading(true)
                sethasNext(false)
                sethasPrevious(false)
                setcount(0)
                setcurrentPage(undefined)

            })
    }

    // product added to cart
    async function itemAddedToCart(added, productData) {
        if (added == 1) { //product added
            if ((location.state.params != "undefined")) {
                if (location.state.params.identity === "caterogries") {

                    getProducts('', productData.cateId, "", "", '', '', page, false);
                }
            }
        }
        if (isOffer == 1) {
            getOfferDetails(false);

        }

    }
    function previousPage() {
        setpage(page - 1)
    }

    function nextPage() {
        setpage(page + 1)
    }

    useEffect(() => {
        if (isOffer == 1) {
            getOfferDetails(true);
        } else {
            loadProductsData(location.state.params);
        }
    }, [page])


    return (
        <div style={{ minHeight: '100vh', flexGrow: 1 }}>
            <HeaderProductList />
            {
                isLoading == true ?
                    <SpinnerLoader /> :
                    <div>
                        <ProductListingComponent
                            isOffer={isOffer}
                            page={page}
                            productData={productData}
                            filterApplied={filterApplied}
                            addedItemToCart={(added, productData) => itemAddedToCart(added, productData)}
                        />
                        {
                            productData.length == 0
                                ? null
                                :

                                (hasNext || hasPrevious) &&

                                (<div style={{ justifyContent: 'center', alignItems: 'flex-end', marginBottom: 20, marginHorizontal: 10, marginTop: 40, flex: 1, display: 'flex', }}>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        {
                                            // hasPrevious ?
                                            <div onClick={() => previousPage()} style={{ visibility: hasPrevious ? 'visible' : 'hidden' }}>
                                                <img src={ArrowLeft} size={30} />
                                            </div>
                                            // : null
                                        }
                                        <div style={{ backgroundColor: '#1d4e4b', borderRadius: 5, alignItems: 'center', display: 'flex', padding: 10 }}>

                                            <p style={{ color: '#ffffff', margin: 0 }}>
                                                {currentPage}
                                            </p>

                                        </div>
                                        {
                                            // hasNext ?
                                            <div onClick={() => nextPage()} style={{ visibility: hasNext ? 'visible' : 'hidden' }}>
                                                <img src={ArrowRight} />
                                            </div>
                                            // : null
                                        }
                                    </div>


                                </div>)

                        }
                    </div>

            }
        </div>
    )
}