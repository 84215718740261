import { useLocation } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../pages/RootPage';
import DrawerIcon from '../assets/svg/header/drawericon.svg';

export default function TermsPage() {
    const { toggleDrawer } = useContext(AuthContext);
    const location = useLocation()
    const [offerData, setofferData] = useState(location?.state?.offerDetails)
    let mediaQueryList = window.matchMedia("(min-width: 992px)");
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)

//check if mobile
function checkDevice(mql) {
    setisLargeDisplay(mql.matches)
}
    useEffect(() => {

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            } else {
                mediaQueryList.removeListener(checkDevice)
            }
        }
    }, [])
    return (
        <div>
            <div style={{ display: 'flex',  justifyContent: 'center', marginLeft: 15, paddingBlock: '2rem' }}>
               { 
               !isLargeDisplay&&
               <div style={{}} >
                    <img src={DrawerIcon} style={{ width: 20, height: 15 }} onClick={() => toggleDrawer()} />
                </div>}
                <p style={{ textAlign: 'center', flex: 1, margin: 0 }}>Offer Terms & Conditions</p>
            </div>
            {offerData}
        </div>
    )
} 