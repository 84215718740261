import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import CategoryPage from "../pages/CategoryPage";
import HomePage from "../pages/HomePage";
import ProductListPage from "../pages/ProductListPage";
import ProductDetailsPage from "../pages/ProductDetailsPage";
import { getProductDetailsLoader } from "./loaders";
import CartPage from '../pages/CartPage'
import RootPage from "../pages/RootPage";
import ShareCataloguePage from "../pages/ShareCataloguePage";
import SearchPage from "../pages/SearchPage";
import AboutUsPage from "../pages/AboutUsPage";
import OffersPage from "../pages/OffersPage";
import TermsPage from "../pages/TermsPage";
import ErrorPage from "../pages/ErrorPage";
import SubCategoryPage from "../pages/SubCategoryPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
export const router = createHashRouter([
    {

        element: <RootPage />,
        children: [{
            path: '/',
            element: <HomePage />,
            errorElement:<ErrorPage/>
        },
        {
            path: "Categories",
            element: <CategoryPage />,
            errorElement:<ErrorPage/>,
        },
        {
            path:"/SubCategories",
            element:<SubCategoryPage/>,
            errorElement:<ErrorPage/>,
        },
        {
            path: "Products",
            element: <ProductListPage />,
            errorElement:<ErrorPage/>
        },
        {
            path: "Productdetails/:product_id",
            element: <ProductDetailsPage />,
            loader: getProductDetailsLoader,
            errorElement:<ErrorPage/>
        },
        {
            path: "Cart",
            element: <CartPage />,
            errorElement:<ErrorPage/>
        },
        // {
        //     path: "Sharecatalogue",
        //     element: <ShareCataloguePage />,
        //     errorElement:<ErrorPage/>
        // },
        {
            path: "Search/:search_name",
            element: <SearchPage />,
            errorElement:<ErrorPage/>
        },
        {
            path:"About",
            element:<AboutUsPage />,
            errorElement:<ErrorPage/>
        },
        {
            path:"Offers",
            element:<OffersPage />,
            errorElement:<ErrorPage/>
        },
        {
            path:"Terms&Conditions",
            element:<TermsPage/>,
            errorElement:<ErrorPage/>

        },
        // {
        //     path:"PrivacyPolicy",
        //     element:<PrivacyPolicy/>,
        //     errorElement:<ErrorPage/>

        // }
        ],
        errorElement:<ErrorPage/>
    },




])

