import { useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

export default function ProductDetailsBrochure({ imagePressed, data, stock }) {

    const [imgIndex, setimgIndex] = useState(0)

    return (

        <div>

            <div  className='product-details-brochure'>
                <TransformWrapper>
                    <TransformComponent>
                        <img src={data[imgIndex]?.image} className='product-details-brochure-image' alt='Product Image'/>
                    </TransformComponent>
                </TransformWrapper>
            </div>

            <div style={{ display: 'flex', flexGrow: 1, marginTop: 30, marginLeft: 7, overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }} >
                {
                    data.map((item, index) => (

                        <div key={index}>

                            <div style={{ borderRadius: 10, marginHorizontal: 8, borderWidth: 0.4, borderColor: imgIndex == index ? 'black' : '#707070', overflow: 'hidden', padding: 10, borderStyle: 'solid' }} onClick={() => setimgIndex(index)}>

                                <img src={item.image} style={{ width: 80, height: 'auto' }} alt='Product image thumbnail'/>
                            </div>

                        </div>

                    ))
                }
            </div>

        </div>

    )

}