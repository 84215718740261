import { memo } from 'react'
import * as language from '../constants/languages'
import { getProductDetails, } from '../services/services';

// get product details
export const getProductDetailsLoader = async ({ params }) => {
    const pid = params.product_id
    const branchId = localStorage.getItem('storeId');
    const token = localStorage.getItem('access_token');
    const productDetails = await getProductDetails(language.companyidValue, pid, branchId, token).catch((error) => { return error })
    return {productDetails}
}
