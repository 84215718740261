import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/CategoryCard.css'
export default function CategoryGrid({ categoryData }) {
    const navigate = useNavigate()
    function showProductList(cateData) {
        if (cateData.subCategories.length == 0) {
            navigate('/Products', {
                state: {

                    params: {
                        identity: 'caterogries',
                        id: cateData.categoryId
                    },
                    heading: cateData.categoryName,
                    parent: 'Categories'
                }
            })
        }

        else if (cateData.parentCategoryId !== null) {

            navigate('/SubCategories',
                {
                    state: {
                        params: {
                            identity: 'subcaterogries',
                            id: cateData.categoryId,
                            categorydata: cateData,
                        },
                        heading: cateData.categoryName,

                    }
                }
            )

        }
        else {

            navigate('/SubCategories',
                {

                    state: {
                        params: {
                            identity: 'caterogries',
                            id: cateData.categoryId,
                        },
                        heading: cateData.categoryName,

                    }
                }
            )
        }
    }
    console.log(categoryData)
    return (
        <div className='grid-container-category'>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignSelf:'center' }}> */}
            {
                categoryData?.map((item, index) => (
                    <div key={item.categoryId} className='grid-item' onClick={() => showProductList(item)}>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center'}}>
                            <img src={item.categoryImage} className='image-category' alt='Category Image'  />
                        </div>
                        <div className='name-container'>
                            <p className='category-name'>{item.categoryName}</p>
                        </div>
                    </div>
                )
                )
            }
            {/* </div> */}
        </div>
    )

}