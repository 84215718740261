import { useEffect, useState } from 'react';
import * as language from '../constants/languages';
import Plus from '../assets/svg/plus.svg'
import Minus from '../assets/svg/minus.svg'
export default function ProductActionSection({ cartQny, productData, removeItem, cartUnit }) {
	const [quantity, setquantity] = useState(cartQny)
	const [message, setmessage] = useState('')
	const [snackopen, setsnackopen] = useState(false)
	useEffect(() => {
		setquantity(cartQny)
	}, [cartQny])
	async function quantityPressed(itemIndex, valueAction) {
		let incrementValue
		if (productData.incrementValue == null) {
			incrementValue = 1
		} else {
			incrementValue = productData.incrementValue
		}
		if (valueAction === 'increment') {

			cartAddRemove(productData.productId, 1) //add qny to cart

		} else {

			if (quantity != incrementValue) {

				cartAddRemove(productData.productId, 0) //reduce qny to cart

			} else {

				removeItem(productData.productId);
			}

		}
	}

	async function cartAddRemove(id, action) {
		let incrementValue
		const branchId = localStorage.getItem('storeId');
		const cart = localStorage.getItem('cartItems')
		let cartItems
		if (!cart) {
			cartItems = {
				customerCartItems: [
				],
				branchId: parseInt(branchId),
				companyId: language.companyidValue
			}
		} else {
			cartItems = JSON.parse(cart);
		}

		if (productData.incrementValue == null) {
			incrementValue = 1
		} else {
			incrementValue = productData.incrementValue
		}
		if (action == 1) { //add to cart

			if (productData.isStockAvailable == true) {
				if (branchId == cartItems.branchId) {
					const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == id);
					var requiredQty = elementsIndex == -1 ? incrementValue : (cartItems.customerCartItems[elementsIndex].qty + incrementValue);
					if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
						if (elementsIndex == -1) {
							cartItems.customerCartItems = [
								...cartItems.customerCartItems,
								{
									product: productData,
									qty: incrementValue,
									variantId: null
								}
							]
						} else {
							cartItems.customerCartItems[elementsIndex] = {
								product: cartItems.customerCartItems[elementsIndex].product,
								qty: cartItems.customerCartItems[elementsIndex].qty + incrementValue,
								variantId: cartItems.customerCartItems[elementsIndex].variantId
							}
						}

						localStorage.setItem('cartItems', JSON.stringify(cartItems))
						setquantity(quantity + incrementValue)
						const event = new CustomEvent('productQtyChange', { token: 2 });
						window.dispatchEvent(event)


					} else {
						setmessage(language.cartAddRemove)
						setsnackopen(true)

					}

				} else {
					setmessage(language.anotherBranch)
					setsnackopen(true)



				}


			} else {
				setmessage(language.cartAddRemove)
				setsnackopen(true)

			}

		} else { //remove from cart
			if (branchId == cartItems.branchId) {
				const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == id);
				if (elementsIndex > -1) {
					var requiredQty = elementsIndex == -1 ? incrementValue : (cartItems.customerCartItems[elementsIndex].qty - incrementValue);
					cartItems.customerCartItems[elementsIndex] = {
						product: cartItems.customerCartItems[elementsIndex].product,
						qty: (requiredQty < 0 ? 0 : requiredQty),
						variantId: cartItems.customerCartItems[elementsIndex].variantId,
					}
					localStorage.setItem('cartItems', JSON.stringify(cartItems));
					setquantity(requiredQty)
					const event = new CustomEvent('productQtyChange', { token: 2 })
					window.dispatchEvent(event)



				} else {
					setmessage(language.cartAddRemove)
					setsnackopen(true)

				}
			} else {
				setmessage(language.anotherBranch)
				setsnackopen(true)


			}


		}
	}

	// add to cart
	async function addToCart() {
		let incrementValue
		let cartItems
		if (productData.incrementValue == null) {
			incrementValue = 1
		} else {
			incrementValue = productData.incrementValue
		}
		if (productData.isStockAvailable == true) {
			const branchId = localStorage.getItem('storeId');

			const cartItemsJson = localStorage.getItem('cartItems');

			if (cartItemsJson == undefined) {
				cartItems = {
					customerCartItems: [
					],
					branchId: parseInt(branchId),
					companyId: language.companyidValue
				}
			} else {
				cartItems = JSON.parse(cartItemsJson);
			}

			if (branchId == cartItems.branchId) {
				const elementsIndex = cartItems.customerCartItems.findIndex(element => element.product.productId == productData.productId);
				var requiredQty = elementsIndex == -1 ? incrementValue : (cartItems.customerCartItems[elementsIndex].qty + incrementValue);
				if (productData.allowNegativeStock == true || requiredQty <= (productData.stock ?? 0)) {
					if (elementsIndex == -1) {
						cartItems.customerCartItems = [
							...cartItems.customerCartItems,
							{
								product: productData,
								qty: incrementValue,
								variantId: null
							}
						]
					} else {
						cartItems.customerCartItems[elementsIndex] = {
							product: cartItems.customerCartItems[elementsIndex].product,
							qty: cartItems.customerCartItems[elementsIndex].qty + incrementValue,
							variantId: cartItems.customerCartItems[elementsIndex].variantId
						}
					}
					localStorage.setItem('cartItems', JSON.stringify(cartItems));
					const event = new CustomEvent('productQtyChange');
					window.dispatchEvent(event)


					setquantity(requiredQty)
					setmessage(language.itemAddedTocart)
					setsnackopen(true)

				} else {
					setmessage(language.cartAddRemove)
					setsnackopen(true)
				}


			} else {
				setmessage(language.anotherBranch)
				setsnackopen(true)
			}

		} else {
			setmessage(language.cartAddRemove)
			setsnackopen(true)
		}

	}

	return (

		<div className='product-details-action-section'>

			{
				cartQny === 0 ?

					<div className='cartWrapperActionSection' onClick={() => addToCart()}>
						<p style={{ fontFamily: 'Poppins-Regular', margin: 0, textAlign: 'center' }}>{language.addToBagLabel}</p>
					</div>

					:

					<div className='quantityWrapperActionSection' >

						<div className='quantityIcon' onClick={() => quantityPressed(1, 'decrement',)}>
							<img src={Minus} size={14} />
						</div>

						<div style={{ marginHorizontal: 10, alignItems: 'center', }}>
							{
								cartUnit != null ?
									<p style={{ fontFamily: 'Poppins-Regular', margin:0}}>{quantity} {cartUnit}</p>
									:
									<p style={{ fontFamily: 'Poppins-Regular', margin:0}}>{quantity}</p>
							}
						</div>

						<div className='quantityIcon' onClick={() => quantityPressed(1, 'increment',)}>
							<img src={Plus} size={14} />
						</div>

					</div>
			}

		</div>

	)
}